import { Link } from "react-router-dom";
import SiteModal from "../siteModal";
import { routes } from "../../routes";

const PayModalComponent = ({
    data = {},
    show = false,
    closeModal = () => null,
    PayItemType = "reportage"
}) => {
    return (
        <>
            <SiteModal
                modalDesign={2}
                title={"نتیجه پرداخت"}
                showModal={show}
                closeModal={() => closeModal()}
            // withCloseLogo
            >

                {PayItemType === "reportage" ?
                    <>
                        <div className="text-center">
                            <img src="/assets/images/success.png" alt="" />
                            <div className="siteTempTitle text-success">
                                <span>
                                    پرداخت رپورتاژ شما
                                </span>
                                {/* {PayItemName ?
                                    <span> ({PayItemName}) </span>

                                    :

                                    <span> به شماره {searchParams.get("token")} </span>
                                } */}
                                <span> با موفقیت انجام شد </span>
                            </div>

                            <div className="payPageText">
                                شما می توانید هم اکنون رپورتاژ خود را منتشر بفرمایید و یا در لیست "رپورتاژ های من" در آینده اقدام به ثبت رپورتاژ موردنظر خود نمایید.
                            </div>

                            <div className="payPageFooter d-flex justify-content-center gap-1 mt-3">
                                <Link to={routes.reportage.campaigns} className="">
                                    <div className="siteTempBtn reverse" style={{ border: 0 }}>
                                        کمیپن های رپورتاژ
                                    </div>
                                </Link>
                                <Link to={routes.reportage.list} className="">
                                    <div className="siteTempBtn">
                                        ساخت رپورتاژ
                                    </div>
                                </Link>
                            </div>


                        </div>
                    </>
                    :
                    <>
                        <div className="text-center">
                            <img src="/assets/images/success.png" alt="" />
                            <div className="siteTempTitle text-success">
                                <span>
                                    پرداخت بک لینک شما
                                </span>
                                {/* {PayItemName ?
                                    <span> ({PayItemName}) </span>

                                    :

                                    <span> به شماره {searchParams.get("token")} </span>
                                } */}
                                <span> با موفقیت انجام شد </span>
                            </div>

                            <div className="payPageText">
                                شما می توانید هم اکنون بک لینک خود را منتشر بفرمایید و یا در قسمت  "کمپین های بک لینک" در آینده اقدام به ثبت لینک موردنظر خود نمایید.
                            </div>

                            <div className="payPageFooter d-flex justify-content-center gap-1 mt-3">
                                <Link to={routes.backLink.campaigns} className="">
                                    <div className="siteTempBtn reverse">
                                        کمیپن های بک لینک
                                    </div>
                                </Link>
                                <Link to={routes.backLink.list} className="">
                                    <div className="siteTempBtn">
                                        ساخت بک لینک
                                    </div>
                                </Link>
                            </div>


                        </div>
                    </>
                }



            </SiteModal>
        </>
    );
}

export default PayModalComponent;