import { useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import jMoment from "moment-jalaali";
import { Link } from 'react-router-dom';
import { perPageArr, reportageStatusArray } from "../../../utilities/config";
import { customSelectStyle, perPageControl } from "../../reactSelectComponens";
import ReportStatus from '../../reportStatus';
import SitePagination from '../../pagination';
import { LoadingData } from '../../loading';
import NoDataComponent from '../../noData';
import { routes } from '../../../routes';
import { addHttpToLink, separateNumberWithComma } from '../../../utilities';
import setupMomentJalaali from '../../../utilities/setupMomentJalaali';

const ReportageListTableRow = ({
    reportage = {},
    handleShowReason = () => null,
}) => {

    setupMomentJalaali();

    const rowRef = useRef(null);

    const SHOW_EDIT_REPORTAGE = ["در انتظار انتشار", "رد شده"];

    const [ShowMoreOption, setShowMoreOption] = useState(false);

    const handleOpenPublishItem = () => {
        if (reportage?.reportage_link) {
            window.open(addHttpToLink(reportage?.reportage_link), "_blank");
        }
    }

    function handleClickOutside(event) {
        if (rowRef.current && !rowRef.current.contains(event.target)) {
            setShowMoreOption(false);
        }
    }

    useEffect(() => {

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setShowMoreOption]);

    return (
        <>
            <tr className="position-relative" ref={rowRef}>
                <td>
                    <div className="reportageCodeBox">
                        {reportage?.id}
                    </div>
                </td>
                <td>
                    {/* publishedReportageTitle */}
                    <div className="d-flex gap-2">
                        <div className={reportage?.status_name === "منتشر شده" ? "publishedReportageTitle" : ""}
                            onClick={handleOpenPublishItem}
                        >
                            {reportage?.title?.replaceAll("_", " ") || "بدون عنوان"}
                        </div>
                        {reportage?.reportage?.is_foreign === 1 &&
                            <>
                                <div className="reportageForeignBadgeTableBox">
                                    خارجی
                                </div>
                            </>
                        }
                    </div>
                </td>
                <td className="text-center">
                    {reportage?.reportage?.news?.name}
                </td>
                <td className="text-center">
                    {(reportage?.publish_at && jMoment(reportage?.publish_at).format("dddd jD jMMMM jYYYY")) || "ثبت نشده"}
                </td>
                <td className="text-center">
                    <ReportStatus reportStatus={reportage?.status_name} withBox={true} handleShowReason={() => handleShowReason(reportage)} />
                </td>
                <td>
                    <div className="customFlexBox justify-content-end gap-2">

                        {SHOW_EDIT_REPORTAGE.indexOf(reportage?.status_name) > -1 &&

                            <>
                                <Link to={routes.reportage.create(reportage?.campaign?.id) + `?reportage=${reportage?.id}&edit=true`}>

                                    <div className="siteTempBtn tableBtn">
                                        <img src="/assets/images/edit1.svg" alt="" />
                                        <div>ویرایش محتوا</div>
                                    </div>
                                </Link>
                            </>

                        }
                        {(reportage?.status_name === "در انتظار ایجاد") &&
                            <>
                                <Link to={routes.reportage.create(reportage?.campaign?.id) + `?reportage=${reportage?.id}`}>
                                    <div className="siteTempBtn tableBtn">
                                        <img src="/assets/images/document-upload.svg" alt="" />
                                        <div>آپلود محتوا</div>
                                    </div>
                                </Link>

                            </>
                        }

                        {reportage?.status_name !== "لغو شده" &&
                            <div className="siteTempBtn design1 _3dotBtn width-fit"
                                onClick={() => setShowMoreOption(!ShowMoreOption)}
                            >
                                <img src="/assets/images/3dot.svg" alt="" />
                            </div>
                        }
                        <div className={"table3dotMainBox" + (ShowMoreOption ? " show" : "")}>
                            <Link to={routes.ticket.add}>
                                <div className="table3dotCard">
                                    <img src="/assets/images/call.svg" alt="" />
                                    <div>پشتیبانی</div>
                                </div>
                            </Link>
                            {reportage?.file &&
                                <>
                                    <a href={reportage?.file} target='_blank' download rel='noreferrer'>

                                        <div className="table3dotCard">
                                            <img src="/assets/images/document-download.svg" alt="" />
                                            <div>دانلود فایل</div>
                                        </div>
                                    </a>
                                </>
                            }
                            <Link to={routes.reportage.reportageOfCampaigns(reportage?.campaign?.id)}>

                                <div className="table3dotCard">
                                    <img src="/assets/images/box-search.svg" alt="" />
                                    <div>مشاهده کمپین</div>
                                </div>
                            </Link>

                        </div>

                    </div>
                </td>
            </tr>
        </>
    );
}

const ReportageListTable = ({
    tableData,
    loading = false,
    showFooter = true,
    currentPage = 1,
    setCurrentPage = () => null,
    perPage = 10,
    setPerPage = () => null,
    handleShowReason = () => null,
}) => {

    const SHOW_EDIT_REPORTAGE = ["در انتظار انتشار", "رد شده"];

    const [ShowMoreOption, setShowMoreOption] = useState(-1);

    const handleShowMoreButton = (index) => {
        if (index === ShowMoreOption) {
            setShowMoreOption(-1)
        } else {
            setShowMoreOption(index)
        }
    }

    return (
        <>
            <div className="siteTempTableBox">
                <table className="siteTempTable">
                    <tr>
                        <th>کد رپورتاژ</th>
                        <th>عنوان رپورتاژ</th>
                        <th className="text-center">رسانه</th>
                        <th className="text-center">تاریخ پیشنهادی انتشار</th>
                        <th className="text-center">وضعیت</th>
                        <th className=""
                            style={{
                                textAlign: 'left',
                                paddingLeft: 20
                            }}
                        >اقدامات</th>
                    </tr>
                    {/* {ReportageData.data?.data?.map((reportage, index) => */}
                    {!loading &&
                        <>
                            {tableData?.data?.map((reportage, index) =>

                                <>
                                    <ReportageListTableRow
                                        key={`reportageRow-${index}`}
                                        reportage={reportage}
                                        handleShowReason={handleShowReason}
                                    />
                                </>

                            )}
                        </>
                    }



                </table>

                {loading &&
                    <>
                        <div className="d-flex align-items-center justify-content-center my-3">
                            <LoadingData />
                        </div>
                    </>
                }
                {(tableData?.data?.length === 0 && loading === false) &&
                    <>
                        <NoDataComponent />
                    </>
                }

            </div>

            {showFooter &&
                <>
                    <div className="siteTempTableFooter mt-3">


                        <Select
                            defaultValue={perPageArr[0]}
                            options={perPageArr}
                            classNamePrefix="react-select"
                            className="react-select-temp"
                            components={{
                                Control: perPageControl
                            }}
                            styles={{
                                ...customSelectStyle,
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? '#EBEBEB' : '#EBEBEB',
                                    borderRadius: 8,
                                    fontSize: 13,
                                    color: "#7E8692",
                                    boxShadow: "0px 1px 11px 0px rgba(212, 212, 212, 0.25)",
                                    padding: "7px 12px",
                                })
                            }}
                            onChange={(newValue) => setPerPage(newValue.value)}
                        />

                        <SitePagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalItems={tableData?.meta?.total || 0}
                            perPage={perPage}
                        />
                    </div>
                </>
            }

        </>
    );
}

export default ReportageListTable;