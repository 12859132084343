import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setCurrentPageTitle_action } from "../../../store/action/app_action";
import { LoadingData } from "../../../components/loading";
import { Link, useSearchParams } from "react-router-dom";
import { verifyOrder } from "../../../service/cart";
import { getUserCartAction } from "../../../store/action/cart_action";
import { routes } from "../../../routes";
import { getUserBacklinkCartAction } from "../../../store/action/backlink_cart_action";
import { getUserForeignReportageCartAction } from "../../../store/action/foreign_reportage_cart_action";

const PayPage = () => {

    const dispatch = useDispatch();

    let [searchParams] = useSearchParams();

    const [Loading, setLoading] = useState(true);
    const [PaymentStatus, setPaymentStatus] = useState("error")
    const [PayItemType, setPayItemType] = useState("reportage") //reportage , backlink
    const [PayItemName, setPayItemName] = useState("")
    const [PayFromWallet, setPayFromWallet] = useState(false);
    const [CampaignDetail, setCampaignDetail] = useState({});

    const handleShowVeriryPaymentModal = () => {
        // let loading = toast.loading("در حال بررسی پرداخت...")
        setLoading(true)
        verifyOrder(searchParams.get("token")).then(({ data }) => {
            // setShowPaymentModal(true)
            dispatch(getUserCartAction());
            dispatch(getUserBacklinkCartAction());
            dispatch(getUserForeignReportageCartAction());
            // toast.dismiss(loading)
            // setTimeout(() => {
            //     setShowPaymentModal(false)
            //     navigate({
            //         pathname: routes.dashboard,
            //         search: '',
            //     });
            // }, 3000);
            setLoading(false)
            setPaymentStatus("success");
            setCampaignDetail(data?.data?.campaign)
        }).catch((err) => {
            console.log(err)
            setPaymentStatus("error");
            setLoading(false)
            // toast.update(loading, {
            //     render: "پرداخت به مشکل خورده است ، بعدا تلاش کنید",
            //     type: "error"
            // })
        })
    }

    useEffect(() => {
        dispatch(setCurrentPageTitle_action("بررسی پرداخت"))
        setPayItemType(localStorage.getItem("payItemType") || "reportage")
        setPayItemName(localStorage.getItem("payItemName") || null)
        if (searchParams.get("token")) {
            handleShowVeriryPaymentModal()
        }
        if(searchParams.get("walletPay")){
            const campaignData = localStorage.getItem("campaign")
            setPayFromWallet(true)
            setPaymentStatus("success")
            if(typeof campaignData === "string"){
                setCampaignDetail(JSON.parse(campaignData || "{}"))
            }
        }
    }, [])

    return (
        <>
            <div className="customBox maxHeightOfPage d-flex align-items-center justify-content-center">
                <div className="checkingPaymentMainBox">

                    {(Loading && !PayFromWallet) ?
                        <>
                            <div className="checkingPaymentLoadingBox">
                                <LoadingData />

                                <div className="checkingPaymentLoadingTitle">
                                    <span>در حال بررسی پرداخت شما به شماره </span>
                                    <span>{searchParams.get("token")}</span>
                                    <span> هستیم ، لطفا منتظر بمونید... </span>
                                </div>

                            </div>
                        </>
                        :
                        <>

                            {PaymentStatus === "success" ?
                                <>
                                    {PayItemType === "reportage" ?
                                        <>
                                            <div className="text-center">
                                                <img src="/assets/images/success.png" alt="" />
                                                <div className="siteTempTitle text-success">
                                                    <span>
                                                        پرداخت رپورتاژ شما
                                                    </span>
                                                    {PayItemName ?
                                                        <span> ({PayItemName}) </span>

                                                        :

                                                        <span> به شماره {searchParams.get("token")} </span>
                                                    }
                                                    <span> با موفقیت انجام شد </span>
                                                </div>

                                                <div className="payPageText">
                                                    شما می توانید هم اکنون رپورتاژ خود را منتشر بفرمایید و یا در لیست "رپورتاژ های من" در آینده اقدام به ثبت رپورتاژ موردنظر خود نمایید.
                                                </div>

                                                <div className="payPageFooter d-flex justify-content-center gap-1 mt-3">
                                                    <Link to={routes.reportage.campaigns} className="">
                                                        <div className="siteTempBtn reverse" style={{ border: 0 }}>
                                                            کمیپن های رپورتاژ
                                                        </div>
                                                    </Link>
                                                    <Link to={CampaignDetail?.id ?routes.reportage.create(CampaignDetail?.id) : routes.reportage.campaigns} className="">
                                                        <div className="siteTempBtn">
                                                            ساخت رپورتاژ
                                                        </div>
                                                    </Link>
                                                </div>


                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="text-center">
                                                <img src="/assets/images/success.png" alt="" />
                                                <div className="siteTempTitle text-success">
                                                    <span>
                                                        پرداخت بک لینک شما
                                                    </span>
                                                    {PayItemName ?
                                                        <span> ({PayItemName}) </span>

                                                        :

                                                        <span> به شماره {searchParams.get("token")} </span>
                                                    }
                                                    <span> با موفقیت انجام شد </span>
                                                </div>

                                                <div className="payPageText">
                                                    شما می توانید هم اکنون بک لینک خود را منتشر بفرمایید و یا در قسمت  "کمپین های بک لینک" در آینده اقدام به ثبت لینک موردنظر خود نمایید.
                                                </div>

                                                <div className="payPageFooter d-flex justify-content-center gap-1 mt-3">
                                                    <Link to={routes.backLink.campaigns} className="">
                                                        <div className="siteTempBtn reverse">
                                                            کمیپن های بک لینک
                                                        </div>
                                                    </Link>
                                                    <Link to={routes.backLink.create(CampaignDetail?.id)} className="">
                                                        <div className="siteTempBtn">
                                                            ساخت بک لینک
                                                        </div>
                                                    </Link>
                                                </div>


                                            </div>
                                        </>
                                    }
                                </>
                                :
                                <>
                                    <div className="text-center">
                                        {/* <img src="/assets/images/success.png" alt="" /> */}
                                        <div className="siteTempTitle text-danger">
                                            <span>
                                                پرداخت شما به شماره
                                            </span>
                                            <span> {searchParams.get("token")} </span>
                                            <span> با خطا مواجه شد </span>
                                        </div>

                                        <div className="d-flex">
                                            <Link to={routes.dashboard} className="mx-auto mt-3">
                                                <div className="siteTempBtn">
                                                    بازگشت به صفحه اصلی
                                                </div>
                                            </Link>
                                        </div>


                                    </div>
                                </>
                            }

                        </>
                    }

                </div>
            </div>
        </>
    );
}

export default PayPage;