import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../header";
import Sidebar from "../sidebar";
import { useEffect, useState } from "react";
import SiteModal from "../siteModal";
import { verifyOrder } from "../../service/cart";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getUserCartAction } from "../../store/action/cart_action";
import { routes } from "../../routes";

const AppLayout = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let [searchParams] = useSearchParams();

    const [ShowPaymentModal, setShowPaymentModal] = useState(false)

    const handleShowVeriryPaymentModal = () => {
        let loading = toast.loading("در حال بررسی پرداخت...")
        verifyOrder(searchParams.get("token")).then(({ data }) => {
            setShowPaymentModal(true)
            dispatch(getUserCartAction());
            toast.dismiss(loading)
            setTimeout(() => {
                setShowPaymentModal(false)
                navigate({
                    pathname: routes.dashboard,
                    search: '',
                });
            }, 3000);
        }).catch((err) => {
            console.log(err)
            toast.update(loading, {
                render: "پرداخت به مشکل خورده است ، بعدا تلاش کنید",
                type: "error"
            })
        })
    }


    // useEffect(() => {
    //     if (searchParams.get("token") && searchParams.get("orderId")) {
    //         console.log(searchParams.get("success"))
    //         handleShowVeriryPaymentModal()
    //     }
    // }, [])

    return (
        <>
            <SiteModal
                modalDesign={1}
                showModal={ShowPaymentModal}
                dialogClassname={"miniModalDialogBox"}
                closeModal={() => setShowPaymentModal(false)}
            >
                <div className="paymentModalMainBox">

                    {searchParams.get("success") === 0 ?
                        <>
                            <div className="text-danger">
                                پزداخت موفقیت آمیز نبود
                            </div>
                        </>
                        :
                        <>
                            <img src="/assets/images/success.png" alt="" />
                            <div className="text-success">
                                پرداخت با موفقیت انجام شد
                            </div>
                        </>
                    }


                </div>
            </SiteModal>

            <div className="container pt-site-10 pb-site-10">
                <div className="row">
                    <div className="sideBarMainBoxSize">
                        <Sidebar />
                    </div>
                    <div className="mainContentBox">

                        <Header />

                        <Outlet />

                    </div>
                </div>
            </div>
        </>
    );
}

export default AppLayout;