import { useEffect, useState } from "react";
import jMoment from "moment-jalaali";
import { useDispatch } from "react-redux";
import { getAllCampaign, getCampaignStatus } from "../../../service/campaign";
import { setCurrentPageTitle_action } from "../../../store/action/app_action";
import ReportStatus from "../../../components/reportStatus";
import SitePagination from "../../../components/pagination";
import { getReportageStatus, getUserReportage } from "../../../service/reportage";
import { Helmet } from "react-helmet-async";
import Select, { components } from 'react-select';
import { perPageArr, reportageStatusArray } from "../../../utilities/config";
import { customSelectStyle, perPageControl } from "../../../components/reactSelectComponens";
import { Link } from "react-router-dom";
import { routes } from "../../../routes";
import { LoadingData } from "../../../components/loading";
import NoDataComponent from "../../../components/noData";
import ReportageListTable from "../../../components/reportage/reportageListTable";
import SiteModal from "../../../components/siteModal";
import RangeDateFromAndToInput from "../../../components/formComponents/rangeDate";


const Control = ({ children, ...props }) => (
    <components.Control {...props}>
        <div className="reactSelectTitleWithImage">
            {/* <img src="/assets/images/swap.svg" alt="" /> */}
            {children}
        </div>
    </components.Control>
);

const MyReportageListPage = () => {

    const dispatch = useDispatch();

    const REPORTAGE_FAKE_DATA = [
        {
            id: 1,
            title: "رپورتاژ تست",
            news: {
                name: "افق"
            },
        },
        {
            id: 1,
            title: "رپورتاژ تست",
            news: {
                name: "افق"
            },
        },
        {
            id: 1,
            title: "رپورتاژ تست",
            news: {
                name: "افق"
            },
        },
        {
            id: 1,
            title: "رپورتاژ تست",
            news: {
                name: "افق"
            },
        },
    ]

    const SHOW_EDIT_REPORTAGE = ["در انتظار ایجاد", "رد شده"];

    const ORDER_BY = [
        {
            label: "پیش فرض",
            value: "پیش فرض",
        },
        {
            label: "در انتظار پرداخت",
            value: "در انتظار پرداخت",
        },
        {
            label: "در انتظار ایجاد ",
            value: "در انتظار ایجاد ",
        },
        {
            label: "درحال بررسی ",
            value: "درحال بررسی ",
        },
        {
            label: "منتشرشده",
            value: "منتشرشده",
        },
        {
            label: "رد شده",
            value: "رد شده",
        },
    ]

    const [ReportageData, setReportageData] = useState({
        loading: true,
        data: {}
    })
    const [ShowMoreOption, setShowMoreOption] = useState(-1);

    const [ActiveFilters, setActiveFilters] = useState({
        searchText: "",
        status: -1,
    })

    const [CurrentPage, setCurrentPage] = useState(1);
    const [PerPage, setPerPage] = useState(10)
    const [ResonModal, setResonModal] = useState({
        show: false,
        data: {},
    })

    const [CanpaignStatus, setCanpaignStatus] = useState({
        loading: true,
        data: [
            {
                label: "پیش فرض",
                value: -1,
            },
        ]
    })

    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");

    const handleGetReportage = () => {
        setReportageData({
            ...ReportageData,
            loading: true,
        })
        getUserReportage({
            searchText: ActiveFilters?.searchText,
            status: ActiveFilters?.status == '-1' ? [3,5] : ActiveFilters?.status,
            fromDate:FromDate?.trim() !== "" ? jMoment(new Date(FromDate)).format("YYYY-MM-DD") : undefined,
            toDate:ToDate?.trim() !== "" ? jMoment(new Date(ToDate)).format("YYYY-MM-DD") : undefined,
            // status: ActiveFilters?.status,
            page: CurrentPage,
            perPage: PerPage,
        }).then(({ data }) => {
            setReportageData({
                loading: false,
                data
            })

        }).catch((err) => {
            console.log(err)
        })
    }

    const handleShowMoreButton = (index) => {
        if (index === ShowMoreOption) {
            setShowMoreOption(-1)
        } else {
            setShowMoreOption(index)
        }
    }

    const handleGetCampaignStatus = () => {
        getCampaignStatus().then(({ data }) => {
            const helpArr = [
                {
                    label: "پیش فرض",
                    value: -1,
                },
            ];

            for (let i = 0; i < data?.length; i++) {
                const element = data[i];
                if(element === "در حال بررسی" || element === "منتشر شده"){
                    helpArr.push({
                        label: element,
                        value: i,
                    })
                }
            }

            setCanpaignStatus(helpArr)

        }).catch((err) => {
            console.log(err)
        })
    }

    const handleShowReasonModal = (reportage) => {
        setResonModal({
            show: true,
            data: reportage
        })
    }

    const handleCloseResonModal = () => {
        setResonModal({
            show: false,
            data: {}
        })
    }

    useEffect(() => {
        handleGetCampaignStatus();
        dispatch(setCurrentPageTitle_action("رپورتاژ های من"));
    }, [])

    useEffect(() => {
        handleGetReportage();
    }, [CurrentPage, PerPage, ActiveFilters , FromDate , ToDate])


    return (
        <>
            <Helmet>
                <title>
                    رپورتاژ های من
                </title>
            </Helmet>

            <SiteModal
                modalDesign={1}
                // title={"رپورتاژ خبر"}
                showModal={ResonModal.show}
                modalClass="reasonModalContentBox"
                closeModal={() => handleCloseResonModal()}
                withCloseLogo
            >

                <div className="reasonModalHeader">
                    <img src="/assets/images/warning-2.svg" alt="" />
                    <div className="reasonModalHeaderTitle">علت رد رپورتاژ</div>
                </div>

                <div className="reasonModalText">
                    {ResonModal.data?.reason}
                </div>


            </SiteModal>

            <div className="d-flex justify-content-between mb-3">

                <div className="d-flex align-items-center gap-2 reportageSearchMainBox">
                    <div className="reportageSearchBox inMyReportagePage">
                        <input
                            type="text"
                            className="siteTempInput"
                            placeholder="جستجو براساس نام رسانه، کد و عنوان رپورتاژ"
                            onChange={e => setActiveFilters({ ...ActiveFilters, searchText: e.target.value })}
                        />
                        <div className="reportageSearchImage"
                        // onClick={handleGetReportages}
                        >
                            <img src="/assets/images/search.svg" alt="" />
                        </div>
                    </div>

                    <Select
                        defaultValue={ORDER_BY[0]}
                        options={CanpaignStatus}
                        classNamePrefix="react-select"
                        className="react-select-temp"
                        components={{ Control }}
                        styles={{
                            ...customSelectStyle,
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? '#EBEBEB' : '#EBEBEB',
                                borderRadius: 8,
                                fontSize: 13,
                                color: "#7E8692",
                                boxShadow: "0px 1px 11px 0px rgba(212, 212, 212, 0.25)",
                                padding: "7px 12px",
                            })
                        }}
                        onChange={(newValue) => setActiveFilters({ ...ActiveFilters, status: newValue.value })}
                    />

                    <RangeDateFromAndToInput 
                        From={FromDate}
                        To={ToDate}
                        setFrom={setFromDate}
                        setTo={setToDate}
                    />

                </div>

                <div className="d-flex gap-2">
                    <div className="siteTempBtn blueBg noHover">
                        <img src="/assets/images/excel.svg" alt="" />
                        <div>
                            خروجی اکسل
                        </div>
                    </div>
                    <Link to={routes.reportage.campaigns} className="d-flex">
                        <div className="siteTempBtn primary ">
                            لیست کمپین ها
                        </div>
                    </Link>
                    {/* <Link to={routes.reportage.list} className="d-flex">
                        <div className="siteTempBtn primary newCapaignInMyRepBtn">
                            <div className="">ساخت کمپین جدید</div>
                            <img src="/assets/images/plus.svg" alt="" />
                        </div>
                    </Link> */}
                </div>
            </div>
            <div className="customBox fullHeightTableInPage">

                <ReportageListTable
                    loading={ReportageData.loading}
                    tableData={ReportageData.data}
                    currentPage={CurrentPage}
                    setCurrentPage={setCurrentPage}
                    perPage={PerPage}
                    setPerPage={setPerPage}
                    handleShowReason={handleShowReasonModal}
                />

                {/* <div className="siteTempTableBox">
                    <table className="siteTempTable">
                        <tr>
                            <th>کد رپورتاژ</th>
                            <th>عنوان رپورتاژ</th>
                            <th className="text-center">رسانه</th>
                            <th className="text-center">تاریخ پیشنهادی انتشار</th>
                            <th className="text-center">وضعیت</th>
                            <th className="text-center">اقدامات</th>
                        </tr>
                        {!ReportageData.loading &&
                            <>
                                {ReportageData.data?.data?.map((reportage, index) =>

                                    <>
                                        <tr className="position-relative">
                                            <td>
                                                <div className="reportageCodeBox">
                                                    {reportage?.id}
                                                </div>
                                            </td>
                                            <td>
                                                
                                                <div className={"" + (index === 0 ? " " : "")}>
                                                    {reportage?.reportage?.title || "بدون عنوان"}
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                {reportage?.reportage?.news?.name}
                                            </td>
                                            <td className="text-center">
                                                {jMoment(reportage?.created_at).format("jD jMMMM jYYYY")}
                                            </td>
                                            <td className="text-center">
                                                <ReportStatus reportStatus={reportage?.status_name} withBox={true} />
                                            </td>
                                            <td style={{
                                                width: 150
                                            }}>
                                                <div className="customFlexBox justify-content-end gap-2">

                                                    {SHOW_EDIT_REPORTAGE.indexOf(reportage?.status_name) > -1 &&

                                                        <>
                                                            <div className="siteTempBtn tableBtn">
                                                                <img src="/assets/images/edit1.svg" alt="" />
                                                                <div>ویرایش محتوا</div>
                                                            </div>
                                                        </>

                                                    }

                                                    <div className="siteTempBtn design1 _3dotBtn width-fit"
                                                        onClick={() => handleShowMoreButton(index)}
                                                    >
                                                        <img src="/assets/images/3dot.svg" alt="" />
                                                    </div>
                                                    <div className={"table3dotMainBox" + (index === ShowMoreOption ? " show" : "")}>
                                                        <Link to={routes.ticket.add}>
                                                            <div className="table3dotCard">
                                                                <img src="/assets/images/call.svg" alt="" />
                                                                <div>پشتیبانی</div>
                                                            </div>
                                                        </Link>
                                                        <div className="table3dotCard">
                                                            <img src="/assets/images/document-download.svg" alt="" />
                                                            <div>دانلود فایل</div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </td>
                                        </tr>
                                    </>

                                )}
                            </>
                        }



                    </table>
                    {ReportageData.loading &&
                        <>
                            <div className="d-flex align-items-center justify-content-center my-3">
                                <LoadingData />
                            </div>
                        </>
                    }
                    {ReportageData.data?.data?.length === 0 &&
                        <>
                            <NoDataComponent />
                        </>
                    }
                </div> */}

                {/* <div className="siteTempTableFooter mt-3">


                    <Select
                        defaultValue={perPageArr[0]}
                        options={perPageArr}
                        classNamePrefix="react-select"
                        className="react-select-temp"
                        components={{
                            Control: perPageControl
                        }}
                        styles={{
                            ...customSelectStyle,
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? '#EBEBEB' : '#EBEBEB',
                                borderRadius: 8,
                                fontSize: 13,
                                color: "#7E8692",
                                boxShadow: "0px 1px 11px 0px rgba(212, 212, 212, 0.25)",
                                padding: "7px 12px",
                            })
                        }}
                        onChange={(newValue) => { setPerPage(newValue.value); setCurrentPage(1) }}
                    />

                    <SitePagination
                        currentPage={CurrentPage}
                        setCurrentPage={setCurrentPage}
                        totalItems={ReportageData.data?.meta?.total || 0}
                        perPage={PerPage}
                    />
                </div> */}



            </div>
        </>
    );
}

export default MyReportageListPage;