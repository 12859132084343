import { useEffect, useState } from "react";
import jMoment from "moment-jalaali";
import { useDispatch } from "react-redux";
import { getAllCampaign, getSingleCampaign } from "../../../../service/campaign";
import { setCurrentPageTitle_action } from "../../../../store/action/app_action";
import ReportStatus from "../../../../components/reportStatus";
import SitePagination from "../../../../components/pagination";
import { getUserReportage } from "../../../../service/reportage";
import { Helmet } from "react-helmet-async";
import Select, { components } from 'react-select';
import { perPageArr, reportageStatusArray } from "../../../../utilities/config";
import { customSelectStyle, perPageControl } from "../../../../components/reactSelectComponens";
import { Link, useParams } from "react-router-dom";
import { routes } from "../../../../routes";
import { LoadingData } from "../../../../components/loading";
import NoDataComponent from "../../../../components/noData";
import ReportageListTable from "../../../../components/reportage/reportageListTable";


const Control = ({ children, ...props }) => (
    <components.Control {...props}>
        <div className="reactSelectTitleWithImage">
            {/* <img src="/assets/images/swap.svg" alt="" /> */}
            {children}
        </div>
    </components.Control>
);

const ReportageListOfCampaignPage = () => {

    const dispatch = useDispatch();

    const { id } = useParams();

    const SHOW_EDIT_REPORTAGE = ["در انتظار ایجاد", "رد شده"];

    const [ReportageData, setReportageData] = useState({
        loading: true,
        data: {}
    })
    const [ShowMoreOption, setShowMoreOption] = useState(-1);

    const [ActiveFilters, setActiveFilters] = useState({
        searchText: "",
        status: "all",
    })

    const [CurrentPage, setCurrentPage] = useState(1);
    const [PerPage, setPerPage] = useState(12)

    const handleGetCampaignData = () => {
        setReportageData({
            ...ReportageData,
            loading: true,
        })
        getSingleCampaign(id).then(({ data }) => {
            setReportageData({
                loading: false,
                data : {
                    ...data , 
                    data : data?.reportage
                }
            })

        }).catch((err) => {
            console.log(err)
        })
    }

    const handleShowMoreButton = (index) => {
        if (index === ShowMoreOption) {
            setShowMoreOption(-1)
        } else {
            setShowMoreOption(index)
        }
    }

    useEffect(() => {
        dispatch(setCurrentPageTitle_action("رپورتاژ های کمپین"));
    }, [])

    useEffect(() => {
        handleGetCampaignData();
    }, [CurrentPage, PerPage])


    return (
        <>
            <Helmet>
                <title>
                    رپورتاژ های کمپین
                </title>
            </Helmet>

            <div className="d-flex justify-content-between mb-3">

                <div className="d-flex align-items-center gap-2">
                    <div className="reportageSearchBox inMyReportagePage">
                        <input
                            type="text"
                            className="siteTempInput"
                            placeholder="جستجو براساس نام رسانه، کد و عنوان رپورتاژ"
                            onChange={e => setActiveFilters({ ...ActiveFilters, searchText: e.target.value })}
                        />
                        <div className="reportageSearchImage"
                        // onClick={handleGetReportages}
                        >
                            <img src="/assets/images/search.svg" alt="" />
                        </div>
                    </div>

                    <Select
                        defaultValue={reportageStatusArray[0]}
                        options={reportageStatusArray}
                        classNamePrefix="react-select"
                        className="react-select-temp"
                        components={{ Control }}
                        styles={{
                            ...customSelectStyle,
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? '#EBEBEB' : '#EBEBEB',
                                borderRadius: 8,
                                fontSize: 13,
                                color: "#7E8692",
                                boxShadow: "0px 1px 11px 0px rgba(212, 212, 212, 0.25)",
                                padding: "7px 12px",
                            })
                        }}
                        onChange={(newValue) => setActiveFilters({ ...ActiveFilters, status: newValue.value })}
                    />

                </div>

                <div className="d-flex gap-2">
                    <div className="siteTempBtn blueBg noHover">
                        <img src="/assets/images/excel.svg" alt="" />
                        <div>
                            خروجی اکسل
                        </div>
                    </div>
                    <Link to={routes.reportage.campaigns} className="d-flex">
                        <div className="siteTempBtn primary">
                            لیست کمپین ها
                        </div>
                    </Link>
                </div>
            </div>
            <div className="customBox">

                <ReportageListTable
                    loading={ReportageData.loading}
                    tableData={ReportageData.data}
                    currentPage={CurrentPage}
                    setCurrentPage={setCurrentPage}
                    perPage={PerPage}
                    setPerPage={setPerPage}
                />

                {/* <div className="siteTempTableBox">
                    <table className="siteTempTable">
                        <tr>
                            <th>کد رپورتاژ</th>
                            <th>عنوان رپورتاژ</th>
                            <th className="text-center">رسانه</th>
                            <th className="text-center">تاریخ پیشنهادی انتشار</th>
                            <th className="text-center">وضعیت</th>
                            <th className="text-center">اقدامات</th>
                        </tr>
                        
                        {!ReportageData.loading &&
                            <>
                                {ReportageData.data?.reportage?.map((reportage, index) =>

                                    <>
                                        <tr className="position-relative">
                                            <td>
                                                <div className="reportageCodeBox">
                                                    {reportage?.id}
                                                </div>
                                            </td>
                                            <td>
                                                 publishedReportageTitle 
                                                <div className={"" + (index === 0 ? " " : "")}>
                                                    {reportage?.title  || "بدون عنوان"}
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                {reportage?.reportage?.news?.name}
                                            </td>
                                            <td className="text-center">
                                                {jMoment(reportage?.created_at).format("jD jMMMM jYYYY")}
                                            </td>
                                            <td className="text-center">
                                                <ReportStatus reportStatus={reportage?.status_name} withBox={true} />
                                            </td>
                                            <td style={{
                                                width: 150
                                            }}>
                                                <div className="customFlexBox justify-content-end gap-2">

                                                    {SHOW_EDIT_REPORTAGE.indexOf(reportage?.status_name) > -1 &&

                                                        <>
                                                            <div className="siteTempBtn tableBtn">
                                                                <img src="/assets/images/edit1.svg" alt="" />
                                                                <div>ویرایش محتوا</div>
                                                            </div>
                                                        </>

                                                    }

                                                    <div className="siteTempBtn design1 _3dotBtn width-fit"
                                                        onClick={() => handleShowMoreButton(index)}
                                                    >
                                                        <img src="/assets/images/3dot.svg" alt="" />
                                                    </div>
                                                    <div className={"table3dotMainBox" + (index === ShowMoreOption ? " show" : "")}>
                                                        <Link to={routes.ticket.add}>
                                                            <div className="table3dotCard">
                                                                <img src="/assets/images/call.svg" alt="" />
                                                                <div>پشتیبانی</div>
                                                            </div>
                                                        </Link>
                                                        <div className="table3dotCard">
                                                            <img src="/assets/images/document-download.svg" alt="" />
                                                            <div>دانلود فایل</div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </td>
                                        </tr>
                                    </>

                                )}
                            </>
                        }



                    </table>
                    {ReportageData.loading &&
                        <>
                            <div className="d-flex align-items-center justify-content-center my-3">
                                <LoadingData />
                            </div>
                        </>
                    }
                    {ReportageData.data?.reportage?.length === 0 &&
                        <>
                            <NoDataComponent />
                        </>
                    }
                </div>

                <div className="siteTempTableFooter mt-3">


                    <Select
                        defaultValue={perPageArr[0]}
                        options={perPageArr}
                        classNamePrefix="react-select"
                        className="react-select-temp"
                        components={{
                            Control: perPageControl
                        }}
                        styles={{
                            ...customSelectStyle,
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? '#EBEBEB' : '#EBEBEB',
                                borderRadius: 8,
                                fontSize: 13,
                                color: "#7E8692",
                                boxShadow: "0px 1px 11px 0px rgba(212, 212, 212, 0.25)",
                                padding: "7px 12px",
                            })
                        }}
                        onChange={(newValue) => { setPerPage(newValue.value); setCurrentPage(1) }}
                    />

                    <SitePagination
                        currentPage={CurrentPage}
                        setCurrentPage={setCurrentPage}
                        totalItems={ReportageData.data?.meta?.total || 0}
                        perPage={PerPage}
                    />
                </div> */}



            </div>
        </>
    );
}

export default ReportageListOfCampaignPage;