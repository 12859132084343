import DatePickerComponent from "../../datePicker";

const RangeDateFromAndToInput = ({
    From,
    To,
    setFrom = () => null,
    setTo = () => null,
}) => {
    return (
        <>

            <div className="d-flex align-items-center gap-2 customBox p-1 px-3 rangeDateMainBox">
                <img src="/assets/images/calendar-gray.svg" alt="" />
                <DatePickerComponent
                    value={new Date(From || "")}
                    placeholder="از تاریخ"
                    onChange={value => setFrom(value)}
                    inputClassName="border-0 text-center"
                    />
                <div>-</div>
                <DatePickerComponent
                    value={new Date(To || "")}
                    placeholder="تا تاریخ"
                    onChange={value => setTo(value)}
                    inputClassName="border-0 text-center"
                />
            </div>

        </>
    );
}

export default RangeDateFromAndToInput;